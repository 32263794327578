<template>
	<van-empty class="empty-wrap" image="network" description="网络错误，请稍后扫码重试！" >
		<van-button round class="bottom-button" @click="backHome">重试</van-button>
	</van-empty>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const siteCode = localStorage.getItem('siteCode')
const tankerName = localStorage.getItem('tankerName')

const backHome = () => {

	router.push({
		path: '/',
		query: {
			site_code: siteCode,
			tanker_name: tankerName,
			tabIndex: 0
		}
	})
}

onMounted(() => {
	setTimeout(() => {
		window.opener = null;
		window.open('', '_self');
		window.close();
	}, 3000)
})
</script>

<style>
.empty-wrap {
	height: 80vh;
}
.bottom-button {
	width: 160px;
	height: 40px;
}
</style>